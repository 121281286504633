@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* border: solid 1px red; */
  font-family: Euclid;
}

@font-face {
  font-family: Euclid;
  src: url("../public/assets/euclid-circular-a-cufonfonts/Euclid\ Circular\ A\ Regular.ttf");
}

@font-face {
  font-family: Poppins;
  src: url("../public/assets/Poppins/Poppins-Bold.ttf");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 10px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #858488;
}

.toggler {
  flex: 0.3;
  display: none;
}

.toggler-icon div {
  width: 25px;
  height: 2px;
  background: black;
  margin: 5px 0px;
  transition: 0.3s ease;
}

.toggler-icon .line-two {
  width: 20px;
}

.toggle .line-one {
  width: 25px;
  height: 2px;
  background: black;
  margin: 5px 0px;
  transform: translate(-5px, 7px) rotate(-45deg);
}

.toggle .line-two {
  width: 25px;
  height: 2px;
  background: black;
  margin: 5px 0px;
  opacity: 0;
}

.toggle .line-three {
  width: 25px;
  height: 2px;
  background: black;
  margin: 5px 0px;
  transform: translate(-5px, -7px) rotate(45deg);
  color: #3d3d775e;
}

.toggler-icon div {
  width: 25px;
  height: 2px;
  background: black;
  margin: 5px 0px;
}

.hero-headers {
  font-size: 2.3rem;
  color: black;
  font-family: Poppins;
  font-weight: 700;
}

.section-container {
  padding: 3rem;
}

.highlight {
  color: #bda9e9;
}

.section-headers {
  font-size: 1.8rem;
  color: black;
  font-family: Poppins;
  margin-bottom: 2rem;
  font-weight: 700;
  color: #54354d13;
}

.profile-subhead {
  color: #656565;
}

.section-description {
  font-size: 1.1rem;
  color: #4f4f4f;
  margin-bottom: 1rem;
}

.contact-description {
  font-size: 1rem;
  color: #4f4f4f;
  margin-bottom: 1rem;
}

@media screen and (max-width: 900px) {
  .toggler {
    display: block;
    flex: 0.1;
  }

  .section-headers {
    font-size: 1.3rem;
    color: black;
    font-family: Poppins;
  }

  .blog-headers {
    font-size: 1.2rem;
    color: white;
    font-family: Poppins;
  }

  .blog-description {
    font-size: 1rem;
    color: #dadada;
  }
}

.button-57 {
  position: relative;
  overflow: hidden;
  border: 1px solid #e11756;
  color: white;
  display: inline-block;
  font-size: 15px;
  line-height: 15px;
  padding: 18px 25px 17px;
  text-decoration: none;
  cursor: pointer;
  background: #e11756;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-57 span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
}

.button-57 span:last-child {
  color: #e11756;
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
}

.button-57:after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  transform: skewY(9.3deg) scaleY(0);
  z-index: 50;
}

.button-57:hover:after {
  transform-origin: bottom center;
  transform: skewY(9.3deg) scaleY(2);
}

.button-57:hover span:last-child {
  transform: translateX(-50%) translateY(-100%);
  opacity: 1;
  transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}
